<template>
  <div class="rating">
    <div
         v-for="(item, index) in rating"
         :key="index"
         :class="[isEdit ? 'rating__item':'']"
         @click="select(index)"
    >
      <div
          v-if="item.value"
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
          <g clip-path="url(#clip0_8086_10252)">
            <path d="M7.9987 1.7207L10.0587 5.89404L14.6654 6.56737L11.332 9.81404L12.1187 14.4007L7.9987 12.234L3.8787 14.4007L4.66536 9.81404L1.33203 6.56737L5.9387 5.89404L7.9987 1.7207Z" fill="#E7B66B" stroke="#E7B66B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </g>
          <defs>
            <clipPath id="clip0_8086_10252">
              <rect width="16" height="16" fill="white" transform="translate(0 0.387695)"/>
            </clipPath>
          </defs>
        </svg>
      </div>
      <div
          v-else
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
          <path d="M7.9987 1.7207L10.0587 5.89404L14.6654 6.56737L11.332 9.81404L12.1187 14.4007L7.9987 12.234L3.8787 14.4007L4.66536 9.81404L1.33203 6.56737L5.9387 5.89404L7.9987 1.7207Z" stroke="#E7B66B" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Rating",
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    ratingValue: Number
  },
  data () {
    return {
      rating: [
        { value: 0 },
        { value: 0 },
        { value: 0 },
        { value: 0 },
        { value: 0 }
      ]
    }
  },
  watch: {
    rating (value) {
      this.$emit('value', value.reduce((count, item) => count + item.value, 0))
    },
    ratingValue: {
      handler () {
        this.rating = this.rating.map((item, index) => {
          if ((index + 1) <= this.ratingValue) {
            item.value = 1
          }
          if (this.ratingValue === 0) {
            item.value = 0
          }

          return item
        })
      },
      immediate: true
    }
  },
  methods: {
    select (index) {
      if (!this.isEdit) {
        return
      }

      if (this.rating.find(item => item.value > 0)) {
        return this.rating = this.rating.map(item => {
          item.value = 0
          return item
        })
      }

      this.rating = this.rating.map((item, i) => {
        if (i <= index) {
          item.value = 1
        } else {
          item.value = 0
        }

        return item
      })
    }
  }
}
</script>

<style scoped lang="scss">
.rating {
  display: flex;
  column-gap: 5px;

  &__item {
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
