<template>
  <div class="craftsman-profile">
    <div class="craftsman-profile__banner">
      <img :src="banner_1" :alt="getImageName(banner_1)" :title="getImageName(banner_1)" 
      >
      <div
          v-html="hello_text"
          class="craftsman-profile__banner-text-block"
      >
      </div>
    </div>

    <div class="craftsman-profile__list-of-craftsman">
      <div
          v-html="`${about_ttl} ${about_txt}`"
          class="craftsman-profile__craftsman-text"
      >
      </div>

      <div class="craftsman-profile__craftsman-block">
        <div
            v-for="item in team_gallery"
            :key="item.id"
            class="craftsman-profile__craftsman"
        >
          <div class="craftsman-profile__craftsman-img">
            <img
                :src="item.imageUrl"
                :alt="getImageName(item.imageUrl)" :title="getImageName(item.imageUrl)" 

            >
          </div>

          <div class="craftsman-profile__craftsman-name-block">
            <div class="craftsman-profile__name">
              {{ item.name }}
            </div>
            <div class="craftsman-profile__position">
              {{ item.position }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
        v-if="ourWorks.length"
        class="craftsman-profile__our-works"
    >
      <div
          v-html="bio_text"
          class="craftsman-profile__our-works-text"
      >
      </div>

      <div
          class="craftsman-profile__our-works-images"
      >
        <template
            v-for="item in ourWorks"
        >
          <router-link
              v-for="(productPassport, i) in item.productPassports"
              :key="i"
              :to="{ name: 'Order history', params: { id: item.id } }"
          >
            <div
                class="craftsman-profile__our-works-item"
                :class="{
              'craftsman-profile__our-works-item_large': isLargeBlock(i)
            }"
            >
              <img :src="productPassport.photo_url" :alt="getImageName(productPassport.photo_url)" :title="getImageName(productPassport.photo_url)" 
              >
            </div>
          </router-link>
        </template>
      </div>
    </div>

    <div class="craftsman-profile__video-block">
      <div v-html="videoTitle" class="craftsman-profile__video-title-block"></div>
      <div
          v-html="videoUrl"
          class="craftsman-profile__video"
      ></div>
    </div>

    <div class="craftsman-profile__select-artisan-block">
      <button
          class="craftsman-profile__select-btn"
          :class="{'craftsman-profile__select-btn_disabled': selectArtisanButtonText !== 'choose the craftsman'}"
          @click="selectArtisan"
      >
        {{ selectButtonText }}
      </button>
    </div>

    <div class="craftsman-profile__reviews">

      <v-dialog
          v-model="isReviewModal"
          max-width="598"
          @click:outside="close"
      >
        <v-card>
          <div class="craftsman-profile__review-modal-header">
            <div
                class="craftsman-profile__review-modal-header-close"
                @click="close"
            >
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
              </svg>
            </div>
          </div>
          <div class="craftsman-profile__review-modal">
            <h2 class="craftsman-profile__review-modal-h2">
              Leave a Review
            </h2>
            <p class="craftsman-profile__review-modal-p">
              How would you rate?
            </p>
            <span class="craftsman-profile__review-modal-message-title">
              Review text:<span class="text-red">*</span>
            </span>
            <textarea
                v-model="formData.message"
                class="craftsman-profile__review-modal-textarea"
                placeholder="Write a review..."
            ></textarea>

            <div class="craftsman-profile__review-modal-rating-block">
              Rate us: <b class="text-red">*</b>
              <rating
                  :ratingValue="formData.rating"
                  :is-edit="true"
                  @value="getRatingValue"
              />
            </div>
            <input
                type="file"
                accept="image/png, image/gif, image/jpeg"
                class="form-control mb-5"
                @change="handleBannerImage"
            >

            <button
                class="craftsman-profile__review-modal-button"
                :class="[formDisabled ? 'craftsman-profile__review-modal-button_disabled':'']"
                :disabled="formDisabled"
                @click="sendReview"
            >
              Submit Review
            </button>
            <p class="craftsman-profile__review-modal-under-text">
              All reviews on Cool Co. Reviews are verified within 48 hours before posting to ensure authenticity and accuracy.
            </p>

          </div>
        </v-card>
      </v-dialog>

      <div class="craftsman-profile__reviews-text">
        <h2 class="craftsman-profile__reviews-text-title">OUR REVIEWS</h2>
        <p class="craftsman-profile__reviews-text-subtitle">
          Your opinion is important to us! If you would like to share your impressions about our products or leave a review, we would love to hear from you. This will help us to become better and please you even more. Thank you for choosing us!
        </p>
      </div>

      <div class="craftsman-profile__actions">
        <div class="craftsman-profile__sort-review-block">
        <!--          Sort by newest review-->
        </div>
        <div class="craftsman-profile__button-block">
          <button
              class="craftsman-profile__button-write-review"
              @click="openReviewModal"
          >
            Write a Review
          </button>
        </div>
      </div>

      <div
          v-for="review in reviews"
          :key="`review-${review.id}`"
          class="craftsman-profile__reviews-wrapper"
      >
        <div class="craftsman-profile__review">
          <div class="craftsman-profile__review-rating d-flex align-center justify-space-between mb-2">
            <div class="craftsman-profile__review-date">
              {{ review.created_at }}
            </div>
            <span
                v-if="review.status === 'pending' && review.author.is_my_review"
                class="craftsman-profile__review-delete"
                @click="deleteMyReview(review.id)"
            >
            Delete
          </span>
          </div>
          <div class="craftsman-profile__review-rating mb-2">
            <rating :rating-value="review.rating" />
          </div>
          <div class="craftsman-profile__reviewer mb-2">
            <div class="craftsman-profile__reviewer-avatar">
              <img v-if="review.author.photo" :src="`https://admin.tumar.com/${review.author.photo}`" :alt="getImageName(review.author.photo)" :title="getImageName(review.author.photo)" 
              >
              <img v-else src="/img/default-review-avatar.jpg" alt="default-review-avatar" title="default-review-avatar">
            </div>
            <div class="craftsman-profile__reviewer-name mb-2">
              {{ review.author.full_name }}
            </div>
          </div>
          <div v-if="review.photo" class="craftsman-profile__product-img">
            <img :src="`https://admin.tumar.com/storage/${review.photo}`" :alt="getImageName(review.photo)" :title="getImageName(review.photo)" >
          </div>
          <p class="craftsman-profile__reviewer-text">
            {{ review.message }}
          </p>
        </div>
        <div class="craftsman-profile__replies-block">
          <div
              v-for="reply in review.replies"
              :key="`reply-${reply.id}`"
              class="craftsman-profile__review"
          >
            <div class="craftsman-profile__review-date">
              {{ review.created_at }}
            </div>
            <div class="craftsman-profile__reviewer mb-2">
              <div class="craftsman-profile__reviewer-avatar">
                <img v-if="reply?.author?.photo" :src="reply?.author?.photo" :alt="getImageName(reply.author.photo)" :title="getImageName(reply.author.photo)" >
                <img v-else src="/img/default-review-avatar.jpg" title="default-review-avatar" alt="default-review-avatar">
              </div>
              <div class="craftsman-profile__reviewer-name mb-2">
                ...
              </div>
            </div>
            <p class="craftsman-profile__reviewer-text">
              {{ reply.message }}
            </p>
          </div>
        </div>
      </div>

    </div>

    <base-modal
        :is-base-modal="isModal"
        :width="480"
        @close="closeModal"
    >
      <template #header>
        <h2 class="craftsman-profile__attention-title">Attention!</h2>
      </template>
      <v-card flat>
        <div class="craftsman-profile__attention">
          <div class="craftsman-profile__attention-content">
            <div class="craftsman-profile__attention-text">
              You have a special item in your basket. You need to complete the purchase in order to buy other items.
            </div>
          </div>
        </div>
      </v-card>
      <template #footer>
        <div class="craftsman-profile__attention-button-block">
          <button
              class="craftsman-profile__attention-button"
              @click="closeModal"
          >
            ok
          </button>
        </div>
      </template>
    </base-modal>
  </div>
</template>

<script>
import axios from '@/core/services/api.service'
import { getImageName } from '@/utils/utils'

import Rating from "@/UI/Rating.vue"
import BaseModal from "@/UI/BaseModal";

import {createNamespacedHelpers} from "vuex";

const { mapGetters } = createNamespacedHelpers('auth')
const { mapActions: Actions_alerts } = createNamespacedHelpers('alerts')

const { mapState: State_Artisan, mapActions: Actions_Artisan } = createNamespacedHelpers('artisan')
const { mapActions } = createNamespacedHelpers('storeCart')

const API_URL = 'https://admin.tumar.com/storage/' // 'http://ovz2.j1146928.m6x5m.vps.myjino.ru/storage/'

export default {
  name: "PublicCompanyPage",

  components: {
    Rating,
    BaseModal
  },
  data () {
    return {
      isModal: false,
      isReviewModal: false,
      formData: {
        rating: 0,
        message: ''
      },
      banner_1: '',

      artisanData: {
        id: null,
        firstName: '',
        lastName: '',
        email: '',
        profilePicture: '',
        position: ''
      },
      hello_text: '',
      about_ttl: '',
      about_txt: '',
      team_gallery: '',
      bio_text: '',
      videoUrl: '',
      videoTitle: '',
      ourWorks: [],
      reviews: [],
      isReviewLoading: false,
      selectArtisanButtonText: 'choose the craftsman',
      isArtisanBusy: false,
      reviewFile: null
    }
  },
  computed: {
    ...State_Artisan(['artisan']),
    ...mapGetters(['isAuthenticated']),
    selectButtonText () {
      if (this.isArtisanSelected && +this.$route.params.id === +this.artisan.id) {
        return 'Selected'
      }

      return this.selectArtisanButtonText
    },
    isArtisanSelected () {
      return Object.keys(this.artisan).length
    },
    formDisabled () {
      return this.isReviewLoading || this.formData.message.length === 0 || this.formData.rating === 0
    }
  },
  mounted() {
    const id = this.$route.params.id
    this.checkArtisan()
    this.getCraftsmanData(id)
    this.getReviews(id)
    this.getOurWorks(id)
  },
  methods: {
    getImageName,
    ...mapActions(['toggleSideCart']),
    ...Actions_Artisan(['setArtisan']),
    ...Actions_alerts(['addAlert']),
    handleBannerImage (e) {
      this.reviewFile = e.target.files[0];
    },
    uploadImage (image) {
      let form = new FormData()

      form.append('photos[]', image)

      return axios.post('/frontend/upload-photos', form, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    },
    isLargeBlock(index) {
      return index % 6 === 2 || index % 6 === 3
    },
    getOurWorks (id) {
      axios.get(`/specialgoods/artisan/${id}/completed-orders`)
      .then((res) => {
        this.ourWorks = res.data.data
      })
    },
    closeModal () {
      this.isModal = false
    },
    close () {
      this.isReviewModal = false
    },
    selectArtisan () {
      if (this.isArtisanBusy) {
        return
      }

      if (this.isArtisanSelected && +this.artisan.id !== +this.$route.params.id) {
        this.isModal = true
        return
      }

      if (this.isArtisanSelected) {
        return this.setArtisan({})
      }

      this.setArtisan(this.artisanData)
      this.toggleSideCart(true)
    },
    checkArtisan () {
      axios.post(`/specialgoods/artisan/${this.$route.params.id}/check`)
      .then((res) => {
        if (!res.data.available) {
          this.selectArtisanButtonText = 'the craftsman is busy'
          this.isArtisanBusy = true
        }
      })
    },
    clearReviewFormData () {
      this.formData.rating = 0
      this.formData.message = ''
    },
    openReviewModal () {
      if (!this.isAuthenticated) {
        return this.$router.push({ path: '/admin/login' })
      }

      this.isReviewModal = true
    },
    getRatingValue (value) {
      this.formData.rating = value
    },
    async sendReview () {
      this.isReviewLoading = true

      const res = await this.uploadImage(this.reviewFile)

      const payload = {
        artisan_id: this.$route.params.id,
        rating: this.formData.rating,
        message: this.formData.message,
        photo: res.data.files[0]
      }

      axios.post('/specialgoods/review/create', payload)
          .then((res) => {
            this.addAlert({ type: 'success', text: 'Sent successfully' })
            this.clearReviewFormData()
            this.getReviews(this.$route.params.id)
          })
      .finally(() => {
        this.isReviewLoading = false
      })
    },
    deleteMyReview (id) {
      axios.delete(`/specialgoods/review/artisan/review/${id}`)
          .then(() => {
            this.addAlert({ type: 'success', text: 'Deleted successfully' })
            this.getReviews(this.$route.params.id)
          })
    },
    getReviews (carftsmanId) {
      axios.get(`/specialgoods/review/artisan/reply/${carftsmanId}`)
          .then((res) => {
            this.reviews = res.data.data
          })
    },
    getCraftsmanData (carftsmanId) {
      axios.get(`/specialgoods/artisan/${carftsmanId}`)
      .then((res) => {
        this.banner_1 = `${API_URL}${res.data.profile.banner_1}`
        this.hello_text = res.data.profile.hello_txt
        this.team_gallery = res.data.profile.tam_gallery.map((member) => {
          member.imageUrl = `${API_URL}${member.imageUrl}`
          return member
        })

        this.artisanData.id = res.data.profile.artisans_id
        this.artisanData.firstName = res.data.first_name
        this.artisanData.lastName = res.data.last_name
        this.artisanData.email = res.data.email
        this.artisanData.profilePicture = res.data.profile.profile_pic
        this.artisanData.position = res.data.profile.position

        this.about_ttl = res.data.profile.about_ttl
        this.about_txt = res.data.profile.about_txt
        this.bio_text = res.data.profile.bio_txt
        this.videoUrl = res.data.profile.video
        this.videoTitle = `${res.data.profile.video_ttl} ${res.data.profile.video_txt}`
      })
    }
  }
}
</script>

<style lang="scss">
.craftsman-profile {
  color: #39423E;
  max-width: 1440px;
  margin: 0 auto;

  &__product-img {
    max-width: 300px;
    margin-bottom: 10px;
    border-radius: 5px;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  &__attention {
    &-title {
      font-size: 20px;
      text-align: center;
      margin: 0;
    }
    &-content {
      min-height: 50px;
      text-align: center;
    }
    &-button {
      &-block {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      background-color: rgb(242, 242, 242);
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
      border-width: 0;
      padding: 5px 10px;
      border-style: unset;
      min-width: 80px;
    }
  }

  &__select-artisan-block {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__select-btn {
    background: #ffd44b;
    border: 1px solid #ccc;
    padding: 5px 20px;
    transition: all .3s;
    min-width: 185px;
    height: 40px;
    text-transform: uppercase;
    position: fixed;
    bottom: 10px;
    z-index: 200;

    &_disabled {
      cursor: not-allowed !important;
      background: #ccc;
      color: #888;
    }
  }

  &__banner {
    position: relative;
    height: 329px;
    background: darkgrey;
    margin-top: 84px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &-text-block {
      position: absolute;
      top: 182px;
      color: #fff;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      max-width: 331px;

      h1 {
        color: #FFF;
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 154%;
        text-transform: uppercase;
      }

      p {
        color: #fff;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 127.49%;
      }
    }
  }

  &__list-of-craftsman {
    max-width: 726px;
    margin: 64px auto 0;
  }

  &__video {
    display: flex;
    justify-content: center;

    &-block {
      margin: 64px auto;
    }
    &-title-block {
      margin: 0 0 64px;

      h2 {
        color: #39423E;
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 154%;
        text-transform: uppercase;
      }
      p {
        color: #39423E;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 152.8%;
      }
    }
  }

  &__craftsman-text {
    max-width: 572px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    margin: 0 auto;


    h2 {
      color: #39423E;
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 154%;
      text-transform: uppercase;
    }
    p {
      color: #39423E;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 152.8%;
    }

  }
  &__craftsman-block {
    margin-top: 54px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  &__craftsman {
    height: 416px;
    margin-bottom: 32px;

    &-img {
      background: darkgrey;
      width: 235px;
      height: 336px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  &__craftsman-name-block {
    margin-top: 20px;
  }
  &__name {
    color: #666;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 154%;
    font-variant: small-caps;
  }
  &__position {
    color: #3E4151;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 127.49%;
  }
  &__our-works {
    margin-top: 108px;

    &-text {
      max-width: 439px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      row-gap: 16px;

      h2 {
        color: #39423E;
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 154%;
        text-transform: uppercase;
        margin: 0;
      }
      p {
        color: #39423E;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 152.8%;
      }

    }
    &-images {
      margin-top: 64px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 8px;
    }

    &-item_large {
      grid-column: span 2;
    }

    &-item {
      height: 390px;
      border: 1px solid #ccc;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        pointer-events: none;
      }
    }
  }
  &__reviews {
    margin: 126px auto 0;
    max-width: 977px;

    &-text {
      max-width: 440px;
      margin: 0 auto 64px;

      display: flex;
      flex-direction: column;
      row-gap: 16px;

      &-title {
        color: #39423E;
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 154%;
        text-transform: uppercase;
        margin: 0;
      }
      &-subtitle {
        color: #39423E;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 152.8%;
      }

    }
    &-subtitle {
      color: #39423E;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 152.8%;
    }
  }
  &__sort-review-block {
    color: #0D0C22;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &__button-write-review {
    border: 1px solid #39423E;
    background: #CFEBCC;
    padding: 9px 28px;
  }
  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__reviews {
    margin-bottom: 120px;
  }
  &__reviews-wrapper {

  }
  &__replies-block {
    width: 80%;
    margin-left: 100px;
  }
  &__review {
    max-width: 634px;
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid #F8F7F4;
    padding-bottom: 30px;
    margin-top: 56px;

    &-delete {
      font-size: 14px;

      &:hover {
        cursor: pointer;
      }
    }

    &-date {
      color: #858585;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
  }
  &__reviewer {
    display: flex;
    column-gap: 12px;
    align-items: center;

    &-avatar {
      width: 36px;
      height: 36px;
      overflow: hidden;
      border-radius: 50%;
      background: #5465FF;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &-name {
      color: #0D0C22;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
    }
    &-position {
      color: #858585;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
    }
    &-text {
      color: #0D0C22;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }
  &__review-modal {
    padding: 20px 32px;

    &-message-title {
      font-size: 14px !important;
    }

    &-header {
      position: sticky;
      top: 20px;
      right: 7px;
      height: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &-close {
        width: 30px;
        height: 30px;
        background: white;

        &:hover {
          cursor: pointer;
        }

        svg {
          width: 100%;
          height: 100%;
          color: #CFCFCF;
          transform: rotate(-90deg);
        }
      }
    }

    &-h2 {
      margin-bottom: 7px;
      color: #0D0C22;
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px; /* 133.333% */
    }
    &-p {
      color: #0D0C22;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 32px; /* 200% */
      margin-bottom: 14px !important;
    }
    &-textarea {
      border: 1px solid #F6F6F6;
      background: #F6F6F6;
      outline: none;
      width: 100%;
      height: 130px;
      padding: 17px 22px;
    }
    &-input-block {
      margin-bottom: 16px;
      display: flex;
      flex-direction: column;
    }
    &-label {
      color: #333;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    &-input {
      border: 1px solid #CFCFCF;
      background: #FFF;
      padding: 9px 28px;
      outline: none;
    }
    &-button {
      border: 1px solid #39423E;
      background: #CFEBCC;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: 36px 0 24px;

      &_disabled {
        background: darkgrey;
        color: grey;

        &:hover {
          cursor: not-allowed;
        }
      }
    }
    &-under-text {
      color: #0D0C22;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    &-rating-block {
      display: flex;
      align-items: center;
      column-gap: 10px;
      margin: 20px 0;
      font-size: 14px;
    }
  }
}
</style>
