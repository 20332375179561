<template>

  <v-dialog
      :value="isBaseModal"
      :max-width="width"
      @click:outside="close"
  >
    <v-card>
      <div class="base-modal__content">
        <div class="base-modal__header">
          <h2 class="base-modal__header-title">
            <slot name="header" />
          </h2>
          <div
              class="base-modal__header_close"
              @click="close"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
            </svg>
          </div>
        </div>
        <div class="base-modal__inner-content">
          <slot />
        </div>
        <div class="base-modal__footer">
          <slot name="footer" />
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "BaseModal",
  props: {
    isBaseModal: Boolean,
    width: {
      type: Number,
      default: 598
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
  }
}
</script>

<style scoped lang="scss">
.base-modal {

  &__header {
    position: sticky;
    right: 7px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &-title {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
    }

    &_close {
      width: 30px;
      height: 30px;
      background: white;

      &:hover {
        cursor: pointer;
      }

      svg {
        width: 100%;
        height: 100%;
        color: #CFCFCF;
        transform: rotate(-90deg);
      }
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
  }
  &__inner-content {
    margin: 10px 16px;
  }
  &__footer {
    display: grid;
    padding: 0 16px;
    width: 100%;
    align-items: center;
    height: 50px;
  }
}

</style>
